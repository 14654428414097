import { FC, useState } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery, useMutation } from "react-relay/hooks";
// @ts-ignore
import graphql from 'babel-plugin-relay/macro';
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components";
import newEnvironment from "../../relay/RelayEnvironment";
import DesignPreviews from "../DesignPreviews";
import { splitQuery } from "./__generated__/splitQuery.graphql";
import { splitMarketplaceOrderDetailMutation } from "./__generated__/splitMarketplaceOrderDetailMutation.graphql";

const StyledDesignPreviews = styled(DesignPreviews)`
    @media (min-width: 983px) {
        margin-left: 200px;
    }
`;

const Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background: #181818;

    @media (min-width: 983px) {
        grid-template-columns: auto auto;
    }

    @media (max-width: 982px) {
        grid-auto-rows: auto auto;
    }
`;

const ContainerText = styled.div`
    padding: 10px;
`;

const H1 = styled.h1`
    font-family: "Poppins", sans-serif;
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0px;
    color: white;
`;

const P = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: white;
`;

const QtyInput = styled.input`
    font-family: "Poppins",sans-serif;
    text-align: center;
    &::-webkit-inner-spin-button {
        opacity: 1
    }
`;

const ConfirmButton = styled.button`
    background: white;
    padding: 13PX;
    text-transform: uppercase;
    font-family: "Poppins",sans-serif;
    font-weight: 800;
    cursor: pointer;
`;

interface Props {
    urlKey: string,
    accessToken: string
}

interface KeyData {
    accessToken: string,
    authToken: string
}

const ZakekeDetailSplit: FC<Props> = ({ urlKey, accessToken }) => {
    const { marketplaceOrderDetail } = useLazyLoadQuery<splitQuery>(
        graphql`
          query splitQuery($accessToken: String!) {
            marketplaceOrderDetail: marketplaceOrderDetailByAccessToken(accessToken: $accessToken) {
                id
                status
                quantity
                design {
                    ...DesignPreviews_previews
                }
            }
          }
        `,
        { accessToken },
    );

    if (!marketplaceOrderDetail) {
        throw new Error(`Invalid access token ${accessToken}`);
    }

    if (!marketplaceOrderDetail.design) {
        throw new Error(`Design not set`);
    }

    const [updatedQuantity, setUpdatedQuantity] = useState('1');

    const updatedQuantityAsNumber = parseInt(updatedQuantity);
    const remainingQuantity = marketplaceOrderDetail.quantity - (Number.isNaN(updatedQuantity) ? 0 : updatedQuantityAsNumber);

    const [commitSplitMarketplaceOrderDetail] = useMutation<splitMarketplaceOrderDetailMutation>(graphql`
        mutation splitMarketplaceOrderDetailMutation(
            $accessToken: String!, $updatedQuantity: Int!
        ) {
            splitMarketplaceOrderDetail (accessToken: $accessToken, updatedQuantity: $updatedQuantity) {
                id
                status
                designerUrl
            }
        }
    `);

    if (marketplaceOrderDetail.status === "COMPLETE") {
        return (
            <Navigate to={`/complete/${urlKey}`} replace />
        );
    }

    return (
        <Container>
            <ContainerText>
                <H1>Is this artwork valid for all the items in your order?</H1>
                <P>Since you have bought multiple pieces for the same product, you can decide to apply this design for all pieces or just a part of them and make a new design.</P>
                <P>Use this design for <QtyInput type="number" min="1" max={marketplaceOrderDetail.quantity.toString()} value={updatedQuantity} onChange={event => setUpdatedQuantity(event.target.value)} /> pieces.</P>

                {(remainingQuantity > 0
                    && <P>You will be able to create up to <strong>{remainingQuantity}</strong> new designs after this one after you save your choice.</P>)
                    || <P>You will be using this design on all the pieces bought.</P>}

                <ConfirmButton onClick={() => {
                    commitSplitMarketplaceOrderDetail({
                        variables: {
                            accessToken,
                            updatedQuantity: parseInt(updatedQuantity)
                        },
                        onCompleted: res => {
                            window.location.href = res.splitMarketplaceOrderDetail.designerUrl;
                        }
                    });
                }}>
                    Confirm
                </ConfirmButton>
            </ContainerText>
            <StyledDesignPreviews designQuery={marketplaceOrderDetail.design} />
        </Container>
    );
};

const ZakekeSplit: FC = () => {
    const { key } = useParams<{ key?: string }>();
    if (!key) {
        throw new Error('Missing key');
    }

    const { accessToken, authToken } = JSON.parse(atob(key)) as KeyData;

    const relayEnvironment = newEnvironment(authToken);

    if (!accessToken) {
        throw new Error('Missing access token');
    }

    return (
        <RelayEnvironmentProvider environment={relayEnvironment} >
            <ZakekeDetailSplit urlKey={key} accessToken={accessToken} />
        </RelayEnvironmentProvider>
    );
};

export default ZakekeSplit;