/**
 * @generated SignedSource<<172368e1966992d455f452b8b5ac85d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type zakekePreviewIframeQuery$variables = {
  productID: number;
  token: string;
};
export type zakekePreviewIframeQuery$data = {
  readonly marketplaceProductPreview: {
    readonly largeUrl: string;
    readonly mobileUrl: string;
  } | null;
};
export type zakekePreviewIframeQuery = {
  response: zakekePreviewIframeQuery$data;
  variables: zakekePreviewIframeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "productID",
        "variableName": "productID"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "MarketplaceIframe",
    "kind": "LinkedField",
    "name": "marketplaceProductPreview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "largeUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobileUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "zakekePreviewIframeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "zakekePreviewIframeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "accd17ab92bdcd337822e30e3917d0b6",
    "id": null,
    "metadata": {},
    "name": "zakekePreviewIframeQuery",
    "operationKind": "query",
    "text": "query zakekePreviewIframeQuery(\n  $productID: Long!\n  $token: String!\n) {\n  marketplaceProductPreview(productID: $productID, token: $token) {\n    largeUrl\n    mobileUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "d07aa2b45618109fec05e813da68066b";

export default node;
